<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Datenschutzerklärung</h1>
        <p>TyresNParts GmbH verpflichtet sich, Ihre personenbezogenen Daten zu schützen und auf klare und transparente Weise darzulegen, welche Daten erhoben und verwendet werden. In der vorliegenden Datenschutzerklärung wird erläutert, wie TyresNParts GmbH personenbezogene Daten verarbeitet, die Sie zur Verfügung stellen oder die erhoben werden, wenn Sie die TyresNParts GmbH Website besuchen oder digitale Dienste nutzen. Bitte lesen Sie die nachstehende Erklärung daher aufmerksam durch.</p>
        <h2>Überblick</h2>
        <ol>
          <li>Verantwortliche Stelle und Kontaktmöglichkeit</li>
          <li>Websites, für die diese Datenschutzerklärung gilt</li>
          <li>Datenschutzbeauftragter</li>
          <li>Cookie-Richtlinie</li>
          <li>Daten, die TyresNParts GmbH je nach Nutzungsverhalten von Ihnen erhebt</li>
          <li>Datensicherheit im Konzern (verbundene Unternehmen im aktienrechtlichen Sinne)</li>
          <li>Weitergabe Ihrer personenbezogenen Daten</li>
          <li>Wie lange bewahrt TyresNParts GmbH Ihre personenbezogenen Daten auf?</li>
          <li>Ihre Rechte gemäß der Datenschutzgesetzgebung</li>
          <li>Datensicherheit</li>
          <li>Änderungen unserer Datenschutzerklärung</li>
        </ol>
        <h2>1. Verantwortliche Stelle und Kontaktmöglichkeit</h2>
        <p>Datenschutzrechtlich Verantwortlicher im Sinne des Art. 4 Nr. 7 DS-GVO ist die</p>
        <p>
          <strong>TyresNParts GmbH GmbH</strong><br>
          <strong>The Squaire 17- Am Flughafen</strong><br>
          <strong>60549 Frankfurt am Main</strong><br>
        </p>
        <p>
          <strong>Email:</strong>	<a href="mailto:info@tyresnparts.com">info@tyresnparts.com</a><br>
          <strong>Telefon:</strong> +49 (0) 69 50 95 44 580
        </p>
        <p>
          Fragen zum Datenschutz können Sie postalisch an die o.g. Anschrift oder unter folgender E-Mail <a href="mailto:privacy@tyresnparts.com">privacy@tyresnparts.com</a> sowie unter folgender Telefonnummer +49 (0) 69 50 95 44 580 an uns richten.
        </p>
        <h2>2. Geltungsbereich dieser Datenschutzerklärung</h2>
        <p>
          Die in dieser Datenschutzerklärung enthaltenen Informationen gelten für die folgenden Websites:
          TyresNParts GmbH e-Business Portal (“TNP Marktplatz”) https://www.tyresnparts.com
          Die Datenschutzerklärung informiert insoweit auch über die Datenverarbeitungen, die im Rahmen einer Registrierung auf unserer Plattform TNP Marktplatz und der Nutzung unserer Plattform für Bestellungen und die Inanspruchnahme zugehöriger Leistungen, erfolgen.
        </p>
        <h2>3. Cookie-Richtlinie</h2>
        <p>Zur Verbesserung der auf der Webseite zur Verfügung gestellten Services setzt TyresNParts GmbH Cookies ein, die Informationen über Ihr Nutzungsverhalten erfassen. Bei Cookies handelt es sich um Dateien, die Ihr Webbrowser beim Besuch einer Website auf Ihrer Festplatte speichert. Durch Cookies können Sie unter gewissen Umständen persönlich entweder direkt (beispielsweise mit einer E-Mail-Adresse) oder indirekt (beispielsweise mit einem einmaligen Identifikationscode eines Cookies, einer IP-Adresse oder dem Identifikationscode eines Gerätes) identifiziert werden. Die gespeicherten Daten können die von Ihnen besuchten Seiten, das Datum und die Uhrzeit Ihres Besuches und andere Tracking-Informationen umfassen.</p>
        <p>
          Sie können Ihren Browser so konfigurieren, dass Sie benachrichtigt werden, wenn Cookies genutzt werden, so dass Sie die Möglichkeit dazu haben, diese zu akzeptieren. Sie können Ihren Browser auch so konfigurieren, dass Cookies deaktiviert werden.
          Weitere Informationen über den Einsatz von Cookies können Sie der Cookies-Richtlinie entnehmen.
        </p>
        <h2>5. Daten, die TyresNParts GmbH je nach Nutzungsverhalten von Ihnen erhebt (Kategorien)</h2>
        <v-simple-table class="mt-4">
          <thead>
            <tr>
              <th>Anlass der Datenerhebung</th>
              <th>Zweck/Aktivität</th>
              <th>Kategorien</th>
              <th>Rechtsgrundlage für die Verarbeitung</th>
              <th>Aufbewahrungsfrist</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Daten, wenn Sie sich für Werbung von TyresNParts GmbH interessieren. („Daten zu Werbezwecken“)</td>
              <td>Zur Versendung von Werbemitteilungen und Newslettern</td>
              <td>Name, Anschrift, E-Mail, Telefonnummer</td>
              <td>Abs. 1 lit. f DSG-VO im Rahmen geschäftlicher Beziehungen.</td>
              <td>bzw. sobald das Recht auf Löschung ausgeübt wird.</td>
            </tr>
            <tr>
              <td>Die von Ihnen angegebenen personenbezogenen Daten, wenn Sie auf unserer Plattform Transaktion durchführen. („Transaktionsdaten“)</td>
              <td>Zur Administration der Transaktionen. Hierzu gehören auch Back-ups zur Absicherung der Transaktionsdaten</td>
              <td>Name, Anschrift, E-Mail, Telefonnummer, FAX Nummer</td>
              <td>Art. 6 Abs. 1 lit. b DSG-VO i. V. m. dem jeweiligen Dienstleistungs-, Geschäftsbesorgungs-, oder sonstigem Vertrag</td>
              <td>Daten werden nach Löschung des Zugangs-Accounts oder Beendigung des Dienstleistungs-, Geschäftsbesorgungs- oder sonstigem Vertrag gelöscht, es sei denn, es sind gesetzliche Aufbewahrungsfristen von der verantwortlichen Stelle zu berücksichtigen.</td>
            </tr>
            <tr>
              <td>Die von Ihnen angegebenen personenbezogenen Daten, wenn Sie an einem Gewinnspiel oder einer Werbeaktion auf der Website teilnehmen („Gewinnspieldaten“)</td>
              <td>Zur Durchführung des Gewinnspiels</td>
              <td>Name, Anschrift, E-Mail, Telefonnummer</td>
              <td>Art. 6 Abs. 1 lit. b DSG-VO</td>
              <td>Daten werden mit Abschluss des Gewinnspiels gelöscht, es sei denn, es sind gesetzliche Aufbewahrungsfristen von der verantwortlichen Stelle zu berücksichtigen.</td>
            </tr>
            <tr>
              <td>Die von Ihnen angegebenen personenbezogenen Daten, wenn Sie an einer Umfrage teilnehmen, einen Fragebogen ausfüllen oder auf eine Befragung antworten („Umfragedaten“)</td>
              <td>Soweit die Umfrage nicht anonym ist, werden ihre personenbezogenen Daten im Rahmen der Umfrage verarbeitet.</td>
              <td>Name, Anschrift, E-Mail, Telefonnummer sowie die Informationen, die Sie im Rahmen der Umfrage an TyresNParts GmbH weitergeben</td>
              <td>Interessenabwägung im Sinne des Art. 6 Abs. 1 lit. f DSG-VO, es sei denn, Sie haben im Sinne des Art. 6 Abs. 1 lit. a ausdrücklich Ihre Zustimmung erteilt.</td>
              <td>Daten werden mit Abschluss der Auswertung der Umfrage, spätestens nach einem Jahr gelöscht, es sei denn, es sind gesetzliche Aufbewahrungsfristen von der verantwortlichen Stelle zu berücksichtigen.</td>
            </tr>
            <tr>
              <td>Die von Ihnen angegebenen personenbezogenen Daten, wenn Sie sich für Werbung von TyresNParts GmbH interessieren. („Daten zu Werbezwecken“)</td>
              <td>Zur Versendung von Werbemitteilungen und Newslettern</td>
              <td>Name, Anschrift, E-Mail, Telefonnummer</td>
              <td>Einwilligung nach Art. 6 Abs. 1 lit. a DSG-VO bzw. Art. 6 Abs. 1 lit. f DSG-VO im Rahmen geschäftlicher Beziehungen.</td>
              <td>Daten werden nach Widerruf der Einwilligung gelöscht bzw. sobald das Recht auf Löschung ausgeübt wird.</td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul>
          <li>Soweit Sie TyresNParts GmbH personenbezogene Daten zur Verfügung stellen, können Sie je nach Anwendung und Kontext gebeten werden, freiwillig weitere Daten anzugeben. Diese Informationen sind als optional gekennzeichnet und helfen TyresNParts GmbH, Kunden besser zu verstehen und ihnen maßgeschneiderte Dienste anzubieten. Diese weiterführenden Informationen werden nur aus statistischen Gründen erhoben. Es werden keine weiteren Rückschlüsse auf die Person gezogen.</li>
          <li>Soweit Sie unsere Webseite besuchen, werden weitere Informationen in Protokollen unserer Webserver erfasst, z. B. Informationen über Ihren Computer wie Ihre IP-Adresse, Ihr Betriebssystem und Ihren Browsertyp („Nutzungsdaten“). Diese Informationen werden von TyresNParts GmbH für die Systemverwaltung verwendet sowie zur Analyse, auf welche Weise die TyresNParts GmbH Website und die digitalen Plattformen genutzt werden. Außerdem kann TyresNParts GmbH mithilfe dieser Informationen eine optimale Infrastruktur für ihre Online-Aktivitäten bereitstellen.</li>
          <li>Die Verarbeitung dieser Informationen erfolgt auf Basis einer Interessenabwägung, d. h. zur Wahrung unseres Interesses an einem effizienten und störungsfreien Betrieb unserer Webseite, sowie unseres Interesses an der Überwachung und Verbesserung unserer Website sowie unserer Produkte und Dienste. Sie werden grundsätzlich in der Art und Weise erhoben, dass keine Rückschlüsse auf eine bestimmte Person gezogen werden können.</li>
        </ul>
        <h2>6. Datenverarbeitung im Konzern (verbundene Unternehmen im aktienrechtlichen Sinne)</h2>
        <p><strong>Internationale Übermittlung personenbezogener Daten</strong></p>
        <p>TyresNParts GmbH gehört zum Michelin-Konzern. TyresNParts GmbH übermittelt gegebenenfalls Ihre personenbezogenen Daten innerhalb des Konzerns an eine seiner Datenbanken mit Sitz außerhalb Ihres Heimatlandes (z.B. als Auftragsverarbeitung).</p>
        <p>Da das Datenschutzniveau weltweit unterschiedlich ist, übermittelt TyresNParts GmbH Ihre personenbezogenen Daten nur an Unternehmen des Michelin -Konzerns außerhalb der Europäischen Union, soweit diese ein adäquates oder ein ähnliches Schutzniveau wie innerhalb der Europäischen Union für personenbezogene Daten bieten.</p>
        <p>Für Datenübermittlungen zwischen Unternehmen des Michelin Konzerns hat das Unternehmen interne Vorschriften zur Übermittlung personenbezogener Daten aus der Europäischen Union oder dem Europäischen Wirtschaftsraum (EWR) erlassen. Einzelheiten zu diesen Vorschriften erhalten Sie per Klick auf die verbindlichen internen Datenschutzvorschriften (Binding Corporate Rules, BCR).</p>
        <p>Die Übermittlung von Daten an Empfänger außerhalb der Europäischen Union wird durch Verträge geregelt, welche die Klauseln der Europäischen Kommission für Lieferanten mit Sitz außerhalb der EU enthalten, um ein ähnliches Datenschutzniveau wie in Ihrem Heimatland zu garantieren.</p>
        <p>TyresNParts GmbH ist verpflichtet, für die Sicherheit Ihrer personenbezogenen Daten zu sorgen. TyresNParts GmbH schützt deshalb zusammen mit seinen Dienstleistern nach aktuellsten Sicherheitsstandards Ihre personenbezogenen Daten.</p>
        <h2>7. Weitergabe Ihrer personenbezogenen Daten</h2>
        <p>Der Kreis der Empfänger Ihrer personenbezogenen Daten ist ausschließlich auf Personen beschränkt, die diese Informationen zur Wahrnehmung ihrer Verantwortlichkeiten benötigen.</p>
        <p>TyresNParts GmbH gibt Ihre persönlichen Daten soweit erforderlich wie folgt weiter:</p>
        <p><strong>Innerhalb der Michelin -Gruppe</strong></p>
        <p>Die verantwortliche Stelle überträgt Ihre personenbezogenen Daten an andere Unternehmen des Michelin-Konzerns, die im aktienrechtlichen Sinne mit ihr verbunden sind. Dies geschieht im Rahmen des ursprünglich festgelegten Verarbeitungszwecks.</p>
        <p><strong>An Auftragsverarbeiter</strong></p>
        <p>TyresNParts GmbH gibt Ihre personenbezogenen Daten gegebenenfalls an Subunternehmen oder externe Dienstleister weiter, die TyresNParts GmbH heranziehen kann, um Ihre Anfrage zu bearbeiten oder die Dienste zu erbringen. Diese Dienstleister oder Subunternehmen sind vertraglich verpflichtet, die erhaltenen personenbezogenen Daten vertraulich zu behandeln und für deren Sicherheit zu sorgen sowie Ihre personenbezogenen Daten nur entsprechend unseren Anweisungen zu nutzen. TyresNParts GmbH bleibt in diesem Fall verantwortliche Stelle im datenschutzrechtlichen Sinne.</p>
        <p><strong>An Dritte</strong></p>
        <p>Aus rechtlichen Gründen: Sofern TyresNParts GmbH verpflichtet ist, Ihre personenbezogenen Daten offenzulegen oder weiterzugeben, um einer rechtlichen Verpflichtung nachzukommen, ihre Geschäftsbedingungen und sonstigen Vereinbarungen durchzusetzen oder anzuwenden oder um die Rechte, das Eigentum oder die Sicherheit der Michelin -Gruppe, ihrer Kunden oder anderer zu schützen.</p>
        <p>Aufgrund Ihrer Einwilligung: Dritte können Informationen über Sie auf unserer Website oder durch unsere Produkte oder Dienste mittels Cookies oder ähnlicher Technologien erheben, um beispielweise personalisierte Werbung bereitzustellen.</p>
        <h2>8. Wie lange bewahrt TyresNParts GmbH Ihre personenbezogenen Daten auf?</h2>
        <p>Grundsätzlich bewahrt TyresNParts GmbH Ihre personenbezogenen Daten nicht länger auf als dies zur Erfüllung des Zwecks, zu dem TyresNParts GmbH die Daten erhoben hat, notwendig ist; hierzu zählen auch die Erfüllung von gesetzlichen Aufbewahrungsfristen, z.B. Rechnungslegungsvorschriften oder Meldepflichten.</p>
        <p>Unter bestimmten Umständen können Sie von TyresNParts GmbH die Löschung Ihrer Daten verlangen. Einzelheiten finden Sie weiter unten im Abschnitt „Ihre Rechte gemäß der Datenschutzgesetzgebung“.</p>
        <p>Soweit notwendig anonymisiert TyresNParts GmbH Ihre personenbezogenen Daten (damit diese nicht mehr mit Ihnen in Verbindung gebracht werden können) zu Forschungs- oder statistischen Zwecken. In diesem Fall kann TyresNParts GmbH diese Daten ohne weitere Benachrichtigung unbefristet nutzen.</p>
        <h2>9. Ihre Rechte gemäß der Datenschutzgesetzgebung</h2>
        <p>Die Datenschutzgesetzgebung verleiht Ihnen bestimmte Rechte, wenn es um die Verarbeitung Ihrer personenbezogenen Daten geht. Diese sind:</p>
        <ul>
          <li>das Recht auf Information über die Datenverarbeitung (Informationspflicht)</li>
          <li>das Recht auf Auskunft</li>
          <li>das Recht auf Berichtigung Ihrer Daten</li>
          <li>das Recht auf Löschung Ihrer Daten</li>
          <li>das Recht auf Einschränkung der Verarbeitung</li>
          <li>das Recht auf Datenübertragbarkeit</li>
          <li>das Recht auf Widerspruch</li>
          <li>das Recht auf Beschwerde bei der zuständigen Datenschutzbehörde</li>
        </ul>
        <p><strong>Wenn Sie Ihre Rechte wahrnehmen wollen, kontaktieren Sie bitte die verantwortliche Stelle wie unter Abschnitt 1 beschrieben.</strong></p>
        <p><strong>Das Recht auf Information über die Datenverarbeitung (Informationspflicht)</strong></p>
        <p>Sie haben das Recht darüber informiert zu werden, wie TyresNParts GmbH Ihre personenbezogenen Daten erhebt und verarbeitet sowie wer die verantwortliche Stelle ist, wie TyresNParts GmbH Ihre personenbezogenen Daten nutzt und welche Rechte Sie in Bezug auf Ihre personenbezogenen Daten haben. Mit der hier vorliegenden Datenschutzerklärung kommt TyresNParts GmbH seiner Verpflichtung nach, Sie zu informieren.</p>
        <p><strong>Das Recht auf Auskunft</strong></p>
        <p>Sie haben das Recht, Auskunft zu den personenbezogenen Daten zu erhalten, soweit TyresNParts GmbH diese über Sie führt.</p>
        <p><strong>Das Recht auf Berichtigung Ihrer Daten</strong></p>
        <p>Falls Ihre personenbezogenen Daten unrichtig oder unvollständig sind, haben Sie das Recht, eine Berichtung und Aktualisierung dieser Daten zu verlangen. Sollte TyresNParts GmbH Ihre Daten an Dritte weitergegeben haben, werden diese, sofern möglich, über etwaige Aktualisierungen, die Sie uns mitteilen, unterrichtet.</p>
        <p><strong>Das Recht auf Löschung Ihrer Daten</strong></p>
        <p>Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu verlangen, wenn keine Gründe mehr vorliegen, dass TyresNParts GmbH diese weiterhin verarbeitet. Sie können Ihr Recht auf Vergessenwerden beispielsweise dann in Anspruch nehmen, wenn die Daten nicht mehr für den Zweck benötigt werden, für den sie ursprünglich erhoben oder verarbeitet wurden, oder wenn Sie Ihre Einwilligung zur Verarbeitung widerrufen haben.</p>
        <p>Hiervon sind Daten ausgenommen, die als Nachweis im Rahmen gesetzlicher Aufbewahrungsfristen gespeichert werden.</p>
        <p><strong>Das Recht auf Einschränkung der Verarbeitung</strong></p>
        <p>Sie haben außerdem das Recht, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken. Wenn Sie von TyresNParts GmbH die Einschränkung Ihrer personenbezogenen Daten verlangen, stellt TyresNParts GmbH jede weitere Verarbeitung dieser Daten ein.</p>
        <p><strong>Das Recht auf Datenübertragbarkeit</strong></p>
        <p>Wenn Sie Ihre personenbezogenen Daten an einen anderen Diensteanbieter übertragen oder eine Kopie Ihrer Daten für eigene Zwecke erhalten möchten, haben Sie das Recht, die Übertragung Ihrer Daten in maschinenlesbarer Form zu verlangen.</p>
        <p><strong>Das Recht auf Widerspruch</strong></p>
        <p>Sie haben das Recht, der Nutzung Ihrer personenbezogenen Daten insbesondere auch zu Werbezwecken zu widersprechen. Sie können Ihr Recht auf Widerspruch gegen eine solche Verarbeitung wahrnehmen, indem Sie (je nach Applikation) die dafür vorgesehenen Felder (wie z.B. die Abmelde-Funktion auf einem Newsletter) aktivieren. Sie können dieses Recht ebenfalls jederzeit wahrnehmen, indem Sie entweder unter privacy@tyresnparts.com uns eine Nachricht hinterlassen oder die unter Abschnitt 1 angegebenen Kontaktmöglichkeiten nutzen.</p>
        <p><strong>Beschwerderecht bei der Aufsichtsbehörde</strong></p>
        <p>Wenn Sie der Ansicht sind, dass die Verarbeitung Sie betreffender personenbezogener Daten gegen das Datenschutzrecht verstößt, haben Sie das Recht eine Beschwerde bei der zuständigen Datenschutzaufsichtsbehörde einzulegen.</p>
        <p>Weitere Informationen über Ihre Datenschutzrechte erhalten Sie vom Hessischen Beauftragten für Datenschutz und Informationsfreiheit, als der für uns örtlich zuständigen Datenschutzaufsichtsbehörde <a href="“https://datenschutz.hessen.de/“">https://datenschutz.hessen.de/</a>.</p>
        <h2>10. Über Datensicherheit</h2>
        <p>Von TyresNParts GmbH wurden technische und organisatorische Maßnahmen umgesetzt, um den Schutz, die Sicherheit und die Integrität Ihrer personenbezogenen Daten zu gewährleisten. Der Zugang zu personenbezogenen Daten ist auf diejenigen Mitarbeiter und Dienstleister beschränkt, die diese Informationen benötigen und die in Bezug auf die Einhaltung von Vertraulichkeitsbestimmungen geschult wurden.</p>
        <p>Auf den kommerziellen Websites von TyresNParts GmbH werden Bankdaten für die Zwecke wirksamer und rechtlich sicherer Zahlungsprozesse erhoben. Entsprechende Mechanismen umfassen z. B. die SSL-Verschlüsselung (damit die Daten für andere unlesbar sind) während der Erhebung und Übermittlung vertraulicher Daten. Besagte Daten werden nur für den Zweck der Online-Zahlung verwendet und werden nicht gespeichert.</p>
        <p>TyresNParts GmbH sichert zu das Möglichste dafür zu tun, dass Ihre personenbezogenen Daten nicht von unbefugten Dritten geändert oder beschädigt werden und dass nicht von unbefugten Dritten darauf zugegriffen wird.</p>
        <h2>11. Änderungen unserer Datenschutzerklärung</h2>
        <p>Unsere Datenschutzerklärung kann von Zeit zu Zeit geändert werden. Jede künftige Änderung dieser Datenschutzerklärung wird auf dieser Seite veröffentlicht.</p>
        <p>Bearbeitungsstand: 21. Oktober 2021</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>


<style scoped>
h2 {
    margin: 20px 0 10px 0;
}
p {
    margin: 10px 0 5px 0;
}
ul {
    margin-top: 20px;
    list-style: disc;
}
</style>
